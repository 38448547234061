import { ref, watch, computed } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import RoleRepository from '@/modules/user/repository/roleRepository'
import i18n from '@/libs/i18n'

export default function useRolesList() {
  // Use toast
  const toast = useToast()

  const refRoleListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'role', label: i18n.t('Modules.User.Label.Role') },
    { key: 'actions', label: i18n.t('Actions') }
  ]
  const perPage = ref(10)
  const totalRoles = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(false)

  const dataMeta = computed(() => {
    const localItemsCount = refRoleListTable.value ? refRoleListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalRoles.value,
    }
  })

  const refetchData = () => {
    refRoleListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchRoles = (ctx, callback) => {
    RoleRepository
      .list({
        search: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value
      })
      .then(response => {
        const { roles, meta } = response.data.data
        callback(roles)
        totalRoles.value = meta.total
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'Admin') return 'danger'
    if (role === 'Director') return 'warning'
    if (role === 'Manager') return 'success'
    if (role === 'Leader') return 'info'
    if (role === 'Staff') return 'primary'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'Admin') return 'ServerIcon'
    if (role === 'Director') return 'SettingsIcon'
    if (role === 'Manager') return 'DatabaseIcon'
    if (role === 'Leader') return 'Edit2Icon'
    if (role === 'Staff') return 'UserIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    fetchRoles,
    tableColumns,
    perPage,
    currentPage,
    totalRoles,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refRoleListTable,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,
  }
}
